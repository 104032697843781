(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

angular.module('app').component('extraServiceAdv', {
  templateUrl: 'components/extra-service-adv/extra-service-adv.html',
  controller: [ExtraServicesAdvController],
  controllerAs: 'vm',
  bindings: {
    code: '=' // availableValues: enum(excursions, luggagePacking, businessLounge)

  }
});
var CODES_LINKS_MAP = {
  hotels: 'https://hotels.alrosa.aero',
  excursions: 'https://experience.tripster.ru/destinations/russia/?exp_partner=alrosa&utm_source=alrosa&utm_term=websky-booking&utm_campaign=affiliates&utm_medium=link',
  luggagePacking: 'https://service.alrosa.aero/',
  businessLounge: 'https://service.alrosa.aero/'
};

function ExtraServicesAdvController() {
  var vm = this;
  console.log(vm.code);

  vm.submitHandler = function () {
    window.open(CODES_LINKS_MAP[vm.code], '_blank');
  };
}

},{}],2:[function(require,module,exports){
"use strict";

require('./components/extra-service-adv/extraServiceAdv');

require('./services/googleAnalyticsTracker');

angular.module('app').run(['googleAnalyticsTracker', function (googleAnalyticsTracker) {
  googleAnalyticsTracker();
}]);

},{"./components/extra-service-adv/extraServiceAdv":1,"./services/googleAnalyticsTracker":3}],3:[function(require,module,exports){
"use strict";

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

angular.module('app').factory('googleAnalyticsTracker', ['watchman', '$interval', googleAnalyticsTracker]);

function googleAnalyticsTracker(watchman, $interval) {
  var EVENTS = {
    registration: 'Registration',
    'cartesian-search-period': 'Search',
    'pricing-variant': 'SelectFare',
    'passengers-check': 'Passengers'
  },
      EVENTS_HANDLERS = {
    'extra-services-modify__request': extraServicesModifyReq,
    'extra-services-remove__request': extraServicesRemoveReq
  },
      ORDER_EVENT_HANDLERS = {
    ORDER_PAYMENT_STARTED: orderPaymentStarted,
    ORDER_PAYMENT_SUCCESSFULLY_FINISHED: orderPaymentFinished
  };
  return init;

  function init() {
    var gtmInterval = $interval(function () {
      if (dataLayer) {
        watchman.addListener(overlooker, 'googleAnalytics');
        watchman.addOrderEventListener(orderOverlooker, 'googleAnalytics');
        $interval.cancel(gtmInterval);
      }
    }, 100, 20);
  }

  function overlooker(keyEvent) {
    var handler = EVENTS_HANDLERS["".concat(keyEvent.name, "__").concat(keyEvent.type)];
    var action = EVENTS[keyEvent.name];
    var category = 'ecommerce';

    if (keyEvent.type === 'response' && action) {
      if (action == 'Registration') {
        category = 'SendForm';
      }

      dataLayer.push({
        event: 'MyEvent',
        eventdata: {
          category: category,
          action: action
        }
      });
    }

    if (handler) {
      handler(keyEvent.data);
    }
  }

  function orderOverlooker(orderEvent, orderInfo) {
    var handler = ORDER_EVENT_HANDLERS[orderEvent.type];

    if (handler) {
      handler(orderInfo);
    }
  }

  function orderPaymentFinished(orderInfo) {
    var esProducts = getExtraServicesAsProducts(orderInfo);
    var flightsProduct = getFlightsAsProduct(orderInfo);
    var products = [];
    products.push(flightsProduct);
    esProducts.forEach(function (es) {
      products.push(es);
    });
    dataLayer.push({
      event: 'MyEvent',
      eventdata: {
        category: 'ecommerce',
        action: 'Purchase'
      },
      ecommerce: {
        currencyCode: 'RUB',
        purchase: {
          actionField: {
            id: orderInfo.header.regnum,
            revenue: orderInfo.prices.totalPrice
          },
          products: products
        }
      }
    });
  }

  function orderPaymentStarted(orderInfo) {
    var esProducts = getExtraServicesAsProducts(orderInfo);
    var flightsProduct = getFlightsAsProduct(orderInfo);
    var products = [];
    products.push(flightsProduct);
    esProducts.forEach(function (es) {
      products.push(es);
    });
    dataLayer.push({
      event: 'MyEvent',
      eventdata: {
        category: 'ecommerce',
        action: 'StartPurchase'
      },
      ecommerce: {
        checkout: {
          actionField: {
            step: 1
          },
          products: products
        }
      }
    });
  }

  function extraServicesModifyReq(data) {
    var action = 'empty';

    if (data.code == 'meal') {
      action = 'AddMeal';
    } else if (data.code == 'insuranceOnline') {
      action = 'AddInsurance';
    } else if (data.code == 'animal') {
      action = 'AddPets1';
    } else if (data.code == 'animal_2') {
      action = 'AddPets2';
    } else if (data.code == 'animal_3') {
      action = 'AddPets3';
    } else if (data.code == 'animal_4') {
      action = 'AddPets4';
    } else if (data.code == 'animal_5') {
      action = 'AddPets5';
    } else if (data.code == 'animal_6') {
      action = 'AddPets6';
    } else if (data.code == 'animal_7') {
      action = 'AddPets7';
    } else if (data.code == 'animal_8') {
      action = 'AddPets8';
    } else if (data.code == 'baggage') {
      if (data.rfisc == '1AA' || data.rfisc == '0CZ' || data.rfisc == 'Y14') {
        action = 'AddLuggage10';
      } else if (data.rfisc == 'Y11' || data.rfisc == '08Y' || data.rfisc == '0C2' || data.rfisc == 'Y20') {
        action = 'AddLuggage20';
      } else if (data.rfisc == 'Y39' || data.rfisc == '08Z' || data.rfisc == '0C5' || data.rfisc == 'Y37') {
        action = 'AddLuggage30';
      } else if (data.rfisc == '0C3') {
        action = 'AddLuggage23';
      } else if (data.rfisc == '0C6') {
        action = 'AddLuggage32';
      }
    } else if (data.code == 'seat') {
      action = 'AddSeats';
    }

    if (action !== 'empty') {
      dataLayer.push({
        event: 'MyEvent',
        eventdata: {
          category: 'ecommerce',
          action: action
        }
      });
    }
  }

  function extraServicesRemoveReq(data) {
    var action = 'empty';

    if (data.code == 'meal') {
      action = 'RemoveMeal';
    } else if (data.code == 'insuranceOnline') {
      action = 'RemoveInsurance';
    } else if (data.code == 'animal' || data.code == 'animal_2' || data.code == 'animal_3' || data.code == 'animal_4' || data.code == 'animal_5' || data.code == 'animal_6' || data.code == 'animal_7' || data.code == 'animal_8') {
      action = 'RemovePets';
    } else if (data.code == 'baggage') {
      action = 'RemoveLuggage';
    } else if (data.code == 'seat') {
      action = 'RemoveSeats';
    }

    if (action !== 'empty') {
      dataLayer.push({
        event: 'MyEvent',
        eventdata: {
          category: 'ecommerce',
          action: action
        }
      });
    }
  }

  function getExtraServicesAsProducts(orderInfo) {
    var extraServices = [];

    if (orderInfo && 'editableExtraServicesByPassengers' in orderInfo) {
      var servicesByPassengers = orderInfo.editableExtraServicesByPassengers;
    }

    if (orderInfo && 'allExtraServicesByPassengers' in orderInfo) {
      var servicesByPassengers = orderInfo.allExtraServicesByPassengers;
    }

    servicesByPassengers.forEach(function (pass) {
      if (pass && 'passengerSegmentServices' in pass) {
        pass.passengerSegmentServices.forEach(function (services) {
          if (services.length) {
            services.forEach(function (service) {
              if ('serviceItems' in service && service.serviceItems.length) {
                service.serviceItems.forEach(function (serviceItem) {
                  extraServices.push({
                    id: serviceItem.rfisc,
                    name: serviceItem.title,
                    category: serviceItem.groupCode,
                    variant: serviceItem.subgroupCode,
                    price: serviceItem.totalPrice,
                    quantity: serviceItem.amount
                  });
                });
              } else if ('serviceItem' in service && _typeof(service.serviceItem) == 'object' && service.code !== 'insurance') {
                extraServices.push({
                  id: service.serviceItem.rfisc,
                  name: service.serviceItem.title,
                  category: service.serviceItem.groupCode,
                  variant: service.serviceItem.subgroupCode,
                  price: service.serviceItem.totalPrice,
                  quantity: service.serviceItem.amount
                });
              } else if ('serviceItem' in service && _typeof(service.serviceItem) == 'object' && service.code == 'insurance') {
                extraServices.push({
                  id: service.serviceItem.id,
                  name: 'insurance',
                  category: service.serviceItem.productCode,
                  variant: service.serviceItem.tins,
                  price: service.serviceItem.price,
                  quantity: 1
                });
              }

              if ('items' in service && service.items.length) {
                service.items.forEach(function (item) {
                  extraServices.push({
                    id: item.rfisc,
                    name: item.title,
                    category: item.groupCode,
                    variant: item.subgroupCode,
                    price: item.totalPrice,
                    quantity: item.amount
                  });
                });
              }
            });
          }
        });
      }
    });
    return extraServices;
  }

  function getFlightsAsProduct(orderInfo) {
    var flightsAsProduct = {
      id: '',
      name: '',
      category: 'flight',
      brand: '',
      variant: 'OW',
      price: '',
      passengers: '',
      quantity: 1
    };

    for (var i = 0; i < orderInfo.flights.length; i++) {
      if (i > 0) {
        var prefix = ', ';
      } else {
        var prefix = '';
      }

      var segment = orderInfo.flights[i];
      segment.flights.forEach(function (flight) {
        flightsAsProduct.id += prefix + flight.carrier + '-' + flight.racenumber;
        flightsAsProduct.name += prefix + flight.origincity + ' - ' + flight.destinationcity;
        flightsAsProduct.brand += prefix + flight.brandName;
      });
    }

    if (orderInfo.flights.length > 1) {
      flightsAsProduct.variant = 'RT';
    }

    flightsAsProduct.price = orderInfo.prices.totalTicket;
    flightsAsProduct.passengers = orderInfo.passengers.length;
    return flightsAsProduct;
  }
}

},{}]},{},[2]);
